import firebase from "firebase/app";
import "firebase/firestore";

let config = {
    apiKey: "AIzaSyBm1_RBwT6SQ5nDKIk4tIdg2URpXfXSqxg",
    authDomain: "fellpeller.firebaseapp.com",
    databaseURL: "https://fellpeller-default-rtdb.firebaseio.com",
    projectId: "fellpeller",
    storageBucket: "fellpeller.appspot.com",
    messagingSenderId: "1099469668090",
    appId: "1:1099469668090:web:cd9a359972b21b3d899343",
    measurementId: "G-2LRMQ8SSZZ"
};

firebase.initializeApp(config);

export default firebase.firestore();